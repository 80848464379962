/*------- 11. About style ------ */
.welcome-area{
  @media #{$xs-layout} {
    padding-top: 50px;
  }
}
.welcome-area.pt-100{
  @media #{$xs-layout} {
    padding-top: 50px;
  }
}

.welcome-content {
  
  h5 {
    font-family: 'BwGradual';
    font-weight: 400;
    font-size: 16px;
    color: $theme-color;
    margin: 0;
    letter-spacing: 0.3px;
    font-weight: 600;
  }
  h1 {
    font-family: 'BwGradual';
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0.3px;
    margin: 8px 0 30px;
    color: #161616;
    position: relative;
    display: inline-block;
    padding: 0 0 23px;
    line-height: 1;
    @media #{$xs-layout} {
      font-size: 28px;
    }
    &:before {
      position: absolute;
      background-color: #070508;
      height: 3px;
      width: 70px;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
    }
  }
  p {
    font-family: 'ALTRiviera';
    font-size: 22px;
    color: #161616;
    line-height: 30px;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 80px;
    font-weight: 400;
    @media #{$lg-layout} {
      width: 80%;
    }
    @media #{$md-layout} {
      width: 95%;
    }
    @media #{$xs-layout} {
      width: 100%;
      font-size: 16px;
      padding-bottom: 30px;
      line-height: 23px;
    }
  }
}

.connect-section {
  position: relative;
  background: url('/assets/images/65ba7f346b545adc3dabf155_NB231228_visual2.webp'); 
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 420px;
  padding-left: 64px;
  padding-right: 64px;
  display: flex;
  margin-top: 50px;
  @media #{$xs-layout} {
    padding: 60px 10px;
    height: auto;
  }

 
  
  .hero-content {
    color: #fff;
    background:$theme-color-2;
    border-radius: 8px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1300px;
    padding: 48px 40px;
    display: flex;
    @media #{$xs-layout} {
      padding: 40px 20px;
    }
    .row{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h2{
      color: #fff;
    }
    p{
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      max-width: 80%;
      @media #{$xs-layout} {
        font-size: 18px;
        font-weight: 300;
        max-width: 100%;
        padding-bottom: 15px;
      }
    }
  }
  
  .hero-content h2 {
    margin-bottom: 25px;
    font-size: 30px;
    font-weight: 600;
    @media #{$xs-layout} {
      font-size: 28px;
      line-height: 1.4;
      font-weight: 400;
    }
  }
  
  .hero-content a {

    background-color: $theme-color;
    color: $theme-color-2;
    border-color: #fff;
    padding: 10px 20px;
    font-weight: 400;
  }
}



.mission{
  h2{
    font-family: 'BwGradual';
    font-weight: 600;
    color: #161616;
    font-size: 28px;
    @media #{$xs-layout} {
      font-size: 25px;
      padding-top: 20px;
    }
    
  }
  p{
    font-family: 'ALTRiviera';
    color: #161616;
    font-size: 17px;
    @media #{$xs-layout} {
      font-size: 16px;
    }
  }
  h5 svg{
    color: $theme-color;
  }
  h5{
    font-family: 'ALTRiviera';
    font-size: 16px;
    font-weight: 500;
  }

} 
.solution{
  h2{
    font-weight: 500;
    color: #161616;
    font-size: 35px;
    text-transform: capitalize;
    line-height: 1.2;
    padding-bottom: 20px;
    @media #{$xs-layout} {
      font-size: 25px;
    }
    
  }
  p{
    color: #161616;
    font-size: 16px;
    @media #{$xs-layout} {
      padding-bottom: 20px;
    }
  }
  h5 svg{
    color: $theme-color;
  }
  h5{
    font-size: 16px;
    font-weight: 500;
  }

} 
.tailered{
  h2{
    font-weight: 600;
    color: #161616;
    font-size: 35px;
    text-transform: capitalize;
    line-height: 1.2;
    padding-top: 100px;
    padding-bottom: 30px;
    @media #{$xs-layout} {
      padding-top: 40px;
      font-size: 25px;
    }
    
  }
  li{
    display: grid;
    gap: 13px;
    padding-bottom: 40px;
    @media #{$xs-layout} {
      max-width: 300px;
      margin: auto;
    }
    
    h3{
      color: #161616;
      font-size: 19px;
      font-weight: 600;
    }
    p{
      color: #161616;
      font-size: 15px;
      font-weight: 400;
    }
    svg{
      font-size: 40px;
    }
  }

} 

.benefits-section {
  background-color: $theme-color-2;
  margin: 50px 0;
  padding: 80px 0;

  .card {
    transition: transform 0.3s ease-in-out;
    background-color: rgb(111 155 135 / 80%);
    border: none;
  }
  .card-body {
    padding: 2rem;
    color: #fff;
    flex-direction: column;
    display: flex;

    h3{
      font-family: 'BwGradual';
      color: #fff;
      font-weight: 600;
    }
    svg{
      color: #fff;
    }
    p{
      font-family: 'ALTRiviera';
      color: #fff;
      font-size: 15px;
      font-weight: 300;
    }
  }
  h2{
    font-family: 'BwGradual';
    color: #fff;
    font-weight: 700;
    font-size: 38px;
    text-transform: capitalize;
    @media #{$xs-layout} {
      font-size: 28px;
      text-align: center;
    }
  }

  .fa-lightbulb, .fa-handshake, .fa-chart-line {
    color: #4caf50; /* Darker green for icons */
  }
}

/* about page */

.single-count {
  .count-icon {
    i {
      font-size: 50px;
      line-height: 1;
      display: inline-block;
      @media #{$md-layout} {
        font-size: 45px;
      }
      @media #{$xs-layout} {
        font-size: 45px;
      }
    }
  }
  h2 {
    & > span {
      color: $theme-color;
      font-size: 40px;
      font-weight: bold;
      margin: 25px 0 12px;
      display: inline-block;
      @media #{$md-layout} {
        margin: 10px 0 8px;
        font-size: 35px;
      }
      @media #{$xs-layout} {
        margin: 6px 0 8px;
        font-size: 35px;
      }
    }
  }
  span {
    color: #333;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 500;
  }
}

.team-wrapper {
  background-color: #f7f7f7;
  .team-img {
    position: relative;

    img {
      width: 100%;
    }

    .team-action {
      background: #fff none repeat scroll 0 0;
      left: 0;
      opacity: 0;
      padding: 10px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 70%;
      transform: translateY(-50%);
      transition: all 0.6s ease 0s;
      width: 100%;
      z-index: 5;
      a {
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        font-size: 14px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        width: 30px;
        margin: 0 3px;
        &.facebook {
          border: 1px solid #3b5998;
          background-color: #3b5998;
          &:hover {
            background-color: transparent;
            color: #3b5998;
          }
        }
        &.twitter {
          border: 1px solid #55acee;
          background-color: #55acee;
          &:hover {
            background-color: transparent;
            color: #55acee;
          }
        }
        &.instagram {
          border: 1px solid #c32aa3;
          background-color: #c32aa3;
          &:hover {
            background-color: transparent;
            color: #c32aa3;
          }
        }
      }
    }
  }
  .team-content {
    padding: 20px 10px 23px;
    h4 {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 3px;
      text-transform: capitalize;
    }
    span {
      font-size: 15px;
      font-style: italic;
    }
  }
  &:hover .team-action {
    top: 50%;
    opacity: 1;
  }
}

.single-mission {
  h3 {
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 20px;
    text-transform: capitalize;
    line-height: 22px;
    color: #333;
    @media #{$xs-layout} {
      margin: 0 0 10px;
    }
  }
  p {
    margin: 0;
  }
}

.about-brand-logo {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}
