/*------- 14. Brand logo style  -------*/
.brand-logo-active{
  max-width: 800px;
  margin: auto;
}
.brand-logo-active,
.brand-logo-active-2 {
  &.owl-carousel {
    .owl-item {
      img {
        display: inline-block;
        width: auto;
      }
    }
  }
  .single-brand-logo,
  .single-brand-logo2 {
    text-align: center;
    img {
      transition: all 0.5s ease 0s;
      height: 70px;
      width: auto;
      @media #{$xs-layout} {
        height: 35px;
      }
    }
    &:hover img {
      transform: scale(1.1);
    }
  }
}

.brand-logo-wrap {
  padding: 100px 90px;
  @media #{$xs-layout} {
    padding: 50px 20px;
  }
}
